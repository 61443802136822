import './Announcement.css';
import React from 'react';
import i18n from '../i18n';
import storage from "../storage";
import Cookies from "universal-cookie";
import Modal from "../modal/Modal";

const cookies = new Cookies();
class Announcement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideAnnouncement: !! cookies.get('hide_announcement')
        };

        this.onCloseAnnouncement = this.onCloseAnnouncement.bind(this);
    }

    onCloseAnnouncement(props) {
        const date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds

        cookies.set('hide_announcement', true, {
            path: "/",           // Cookie available on all routes
            expires: date,       // Expiration date
            secure: true,        // Optional: for HTTPS only
            sameSite: "strict",  // Optional: prevent CSRF attacks
        })

        this.setState({
            hideAnnouncement: true
        })
    }
    
    render() {
        return (
            <div>
                {storage.company.active_announcement && ! this.state.hideAnnouncement ?
                    <Modal
                        className="announcement-modal"
                        showModal={! this.state.hideAnnouncement}
                        onClose={this.onCloseAnnouncement}
                        // style={{backgroundImage: `url(/announcement-bg.jpg)`}}
                        titleLg={true}
                    >
                        <span className="announcement-message" dangerouslySetInnerHTML={{ __html: i18n.get_generic(storage.company, 'announcement_message') }} />
                    </Modal> : ''
                }
            </div>
        );
    }
}
export default Announcement;