import storage from "./storage";

const config = {
  imagesBaseURL: 'https://manager.present-team.eu/images/',
  imagesDevBaseURL: 'https://manager.presentteam.eu/images/',
  //imagesBaseURL: 'http://localhost/iarts/eshop/admin/images/',
  eshop_title: 'Present Team',
  // enviroment: 'development',
  enviroment: 'production'
};

window.config = config;
export default config;