import './ProductsPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import CategoriesItem from '../categories-item/CategoriesItem';
import request from '../request';
import ProductItem from '../product-item/ProductItem';
import ProductListItem from '../product-list-item/ProductListItem';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faAngleLeft, faAngleRight, faFilter, faSort, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import history from '../history';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import storage from '../storage';
import InputRange from '../range-input/RangeInput';
import ReactTooltip from "react-tooltip";

class ProductsPage extends React.Component {
  constructor(props) {
    super(props);

    const locationClues = history.location.pathname.split('/');
    
    let categoryId;
    // if (locationClues.length === 3) {
    //   categoryId = history.location.pathname.split('/').slice(-1)[0];
    //   page = this.state.page;
    // } else if (locationClues.length === 4) {
    //   categoryId = history.location.pathname.split('/').slice(-2)[0];
    //   page = parseInt(history.location.pathname.split('/').slice(-1)[0]);
    // }
    let local_category = false;
    if(locationClues.length > 2){
      categoryId = locationClues[2]
    }else{
      if(storage.categories_tree){
        categoryId = storage.categories_tree.length > 1 ? 0 : storage.categories_tree[0].id;
        local_category = true
      }
    }

    if(local_category){
      let pathname = '/categories/' + categoryId + '/' + 1;

      history.push(pathname);
    }

    this.state = {
      products: [],
      fetchingProducts: false,
      fetchIsDirty: false,
      categories: [],
      subcategories: [],
      currentCategory: [],
      categoriesExpanded: [],
      attributes: [],
      category: '',
      page: 1,
      pagesCount: 0,
      total: 0,
      displayedProducts: [],
      showFilters: false,
      sortMenuOpen: false,
      sort: {
        by: 'date',
        type: 'desc',
      },
      viewTypeMenuOpen: false,
      viewType: 'grid',
      filterAttributesValues: [],
      filters: {
        price: {
          from: 0,
          to: -1,
          max: -1,
        },
      },
    };

    this.onPriceRangeChangeTimeout = null;

    this.elementRef = React.createRef();

    this.sort = this.sort.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.chooseFilterAttributesValue = this.chooseFilterAttributesValue.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    
    document.title = i18n.t("products_page_title");
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    this.onWindowClick = (e) => {
      if (!this.elementRef.current.querySelector('.btn-sort').contains(e.target)) {
        this.setState({
          ...this.state,
          sortMenuOpen: false,
        });
      }
    }

    this.locationPathname = history.location.pathname;

    window.addEventListener('click', this.onWindowClick);

    this.setState({
      ...this.state,
      categories: storage.categories,
      fetchingProducts: true,
    });

    this.clearStorageOnChangeIndex = storage.onChange(() => {
      this.setState({
        ...this.state,
        categories: storage.categories,
      });
    });

    this.onHistoryChangeUnlisten = history.listen(() => {
      const locationClues = history.location.pathname.split('/');
      let categoryId;
      let local_category = false
      if (locationClues.length > 2) {
        categoryId = locationClues[2];
      }else{
        if(storage.categories_tree){
          categoryId = storage.categories_tree.length > 1 ? 0 : storage.categories_tree[0].id;
          local_category = true
        }
        
      }

      if (this.state.category !== categoryId && !local_category) {
        this.setState({
          ...this.state,
          products: [],
          attributes: [],
          page: 1,
          filterAttributesValues: []
        }, () => {this.getProducts()});
      }else{
        setTimeout(() => {
          this.getProducts(true);
        });
      }

      if (categoryId === 'products') {
        this.getProducts();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    
    document.title = i18n.t("products_page_title");
    if (prevState.fetchingProducts !== this.state.fetchingProducts) {
      this.setState({
        ...this.state,
        fetchIsDirty: true,
      });
    }
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
    this.onHistoryChangeUnlisten();
    storage.clearOnChange(this.clearStorageOnChangeIndex);
    window.removeEventListener('click', this.onWindowClick);
  }

  getProducts(isLite = false) {
    if (!history.location.pathname.includes('categories') && !history.location.pathname.includes('search')) { // page changed FIXME
      return;
    }
    
    this.setState({
      ...this.state,
      fetchingProducts: true,
    });

    const locationClues = history.location.pathname.split('/');
    
    let categoryId;
    let page = 1;
    let filterAttributesValuesParam = '';
    let selectedAttributes = '';
    // if (locationClues.length === 3) {
    //   categoryId = history.location.pathname.split('/').slice(-1)[0];
    //   page = this.state.page;
    // } else if (locationClues.length === 4) {
    //   categoryId = history.location.pathname.split('/').slice(-2)[0];
    //   page = parseInt(history.location.pathname.split('/').slice(-1)[0]);
    // }
    let local_category = false;
    if(locationClues.length > 2){
      categoryId = locationClues[2]
    }else{
      if(storage.categories_tree){
        categoryId = storage.categories_tree.length > 1 ? 0 : storage.categories_tree[0].id;
        local_category = true
      }
    }
    if(locationClues.length > 3){
      page = locationClues[3]
    }
    if(locationClues.length > 4){
      selectedAttributes = locationClues[4].split(',').filter( (a) => a != '').join(',');
      filterAttributesValuesParam = `&attributes=${selectedAttributes}`
    }
    // console.log(locationClues)

    // let filterAttributesValuesParam = '';
    // if (this.state.filterAttributesValues.length > 0) {
    //   filterAttributesValuesParam = `&attributes=${this.state.filterAttributesValues.join(',')}`;
    // }

    let getProductsURL;
    if (history.location.pathname.includes('categories')) {
      getProductsURL = `api/products/category/${categoryId}?page=${page}${filterAttributesValuesParam}&order_by=${this.state.sort.by}&order_type=${this.state.sort.type}${isLite ? '&lite=1' : ''}`;
    } else if (history.location.pathname.includes('/search/products')) {
      const searchQuery = history.location.pathname.split('/').slice(-1)[0];
      getProductsURL = `api/search/products?q=${searchQuery}`;
    }
    /////////
    request.get(`api/categories/${categoryId}`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.state.currentCategory = response.data;
    });
    ///////
    request.get(getProductsURL).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      let attributes = this.state.attributes;
      if (response.data.data && response.data.data.attributes && (attributes.length === 0 || isLite === false)) {
        attributes = response.data.data.attributes;
        if (!('attributes' in response.data.data)) {
          attributes = [];
        }

      }
      
      if (response.data.data) {

        
        this.setState({
          ...this.state,
          page: page,
          category: categoryId,
          total: response.data.data.total,
          pagesCount: Math.ceil(response.data.data.total / 12),
          products: response.data.data.products.map((product) => {
            return {
              id: product.id,
              name: product.title,
              name_en: product.title_en,
              code: product.code,
              description: product.description,
              image: product.thumbnail || product.images[0],
              rating: product.rating,
              price: product.price,
              availability: product.availability,
              variants: product.variants,
              colors: product.colors,
              manufacturer: product.manufacturer,
              discount: product.discount ? product.discount : null,
              scale_discount: product.scale_discount ? product.scale_discount : null,
              campain: product.campain ? product.campain : null
            };
          }),
          subcategories: 'categories' in response.data.data ? response.data.data.categories.map((subcategory) => {
            return {
              id: subcategory.id,
              name: subcategory.name,
              name_en: subcategory.name_en,
              image: subcategory.image,
              fallback_image: subcategory.fallback_image,
              productCount: subcategory.productsCount
            };
          }) : [],
          fetchingProducts: false,
          attributes: attributes,
          filters: {
            ...this.state.filters,
          },
          filterAttributesValues: selectedAttributes.split(',')
        });
      }
    });
  }

  sort(info) {
    const property = info.split('-')[0];
    const order = info.split('-')[1];

    this.setState({
      ...this.state,
      sortMenuOpen: false,
      sort: {
        by: property,
        type: order,
      },
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  chooseFilterAttributesValue(value) {
    const fav = this.state.filterAttributesValues.slice(0);
    const valueIndex = fav.indexOf(value);
    if (valueIndex > -1) {
      fav.splice(valueIndex, 1);
    } else {
      fav.push(value);
    }

    let pathname = '/categories/' + this.state.category + '/' + 1;

    if(fav.length > 0){
      pathname += '/' + fav.join(',');
    }
    history.push(pathname);

    document.querySelector('.product-items-wrapper').scrollIntoView();

    // setTimeout(() => {
    //   this.getProducts(true);
    // });

    this.setState({
      ...this.state,
      filterAttributesValues: fav,
      page: 1
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  changePage(page) {

    let pathname = history.location.pathname;
    const clues = pathname.split('/');
    clues[3] = page;
    
    history.push(clues.join("/"));

    document.querySelector('.product-items-wrapper').scrollIntoView();
    this.setState({
      ...this.state,
      page: page,
    });
    // setTimeout(() => {
    //   this.getProducts(true);
    // });
  }

  render() {
    return (
    <div className="container">
      <div
        className="products-page"
        ref={this.elementRef}
      >
        <div className='filters-wrapper'>
          <div className="options">
            <div className="d-flex">
              <div className='filter--toggle'>
                <button
                  type="button"
                  className={`btn ${this.state.showFilters === false ? 'btn-outline' : ''} ml-1`}
                  onClick={() => this.setState({ ...this.state, showFilters: !this.state.showFilters, })}
                >
                  <FontAwesomeIcon icon={faFilter} />
                  &nbsp;
                  {i18n.t('filters')}
                </button>
              </div>
              
              
            </div>
          </div>
          <div className={`mobile-fixed-filters filters ${this.state.viewType} ${this.state.showFilters ? '' : 'mobile-hidden'} p-1`} >
            <div className='mobile-attributes-backdrop' onClick={() => {
              this.setState({
                ...this.state,
                showFilters: false
              })
            }}></div>
            {/* <div className="price">
              <div className="header">{i18n.t('price')}</div>
              <div>
                <div className="d-flex flex-row">
                  <div className="price-input">
                    <label htmlFor="priceFrom" className="d-none">Price from</label>
                    <input
                      id="priceFrom"
                      type="text"
                      value={this.state.filters.price.from}
                      onChange={this.onPriceFromChange}
                      onKeyDown={(e) => e.code === 'Enter' && this.onPriceFromChange(e)}
                    />
                    <span>€</span>
                  </div>
                  <div className="price-input">
                    <label htmlFor="priceTo" className="d-none">Price to</label>
                    <input
                      id="priceTo"
                      type="text"
                      value={this.state.filters.price.to}
                      onChange={this.onPriceToChange}
                      onKeyDown={(e) => e.code === 'Enter' && this.onPriceToChange(e)}
                    />
                    <span>€</span>
                  </div>
                </div>

                <InputRange
                  onChange={this.updatePriceRange}
                  from={(this.state.filters.price.from / this.state.filters.price.max) * 100}
                  to={(this.state.filters.price.to / this.state.filters.price.max) * 100}
                />
              </div>
            </div> */}
            <div className="attributes">
              {
                this.state.attributes.filter(a => a.id == 1).map((attribute, i) => {
                  return (
                    <div
                      className="attribute"
                      key={`attr-category-${i}`}
                    >
                      <div className="name">{i18n.get_name(attribute)}</div>
                      <div className="choices">
                        {
                          attribute.values.map((color, i) => {
                            if(color.custom_group_code != null && color.custom_group_code != ''){
                              let colors = [];
                              let grad;
                              if(color.custom_group_code.indexOf("|") > -1){
                                colors = color.custom_group_code.split("|");
                                grad = 'linear-gradient(to right top';
                                colors.forEach( (c) => {
                                  grad += ', #' + c;
                                } )
                                grad += ')';
                              }else{
                                grad = "#" + color.custom_group_code;
                              }
                              
                              //linear-gradient(to right top, #ff0000, #f36300, #de9200, #c2b700, #a0d600, #74dd54, #46e085, #00e1ad, #00c6d9, #00a5ff, #0078ff, #0000ff)
                                return (
                                  <div
                                  key={`attr-child-${i}`}
                                    className={`color-choice choice ${this.state.filterAttributesValues.indexOf(color.id) > -1 ? 'selected' : ''}`}
                                    onClick={() => this.chooseFilterAttributesValue(color.id)}
                                  >
                                    <div 
                                        className="color-value" 
                                        style={{background: grad}}
                                        data-tip data-for={`registerTip-filter-${color.id}`}
                                      >
                                        <ReactTooltip id={`registerTip-filter-${color.id}`} place="top" effect="solid">
                                          {color.custom_group_text}
                                        </ReactTooltip>
                                      </div>
                                  </div>
                                  
                                )
                              //}
                            }
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
              {
                
                this.state.attributes.filter(a => a.id == 2).map((attribute, i) => {
                  let categories = attribute.values.map(f => f.custom_category_text).filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  })

                  return categories.map(c => {
                    return (
                      <div
                        className="attribute"
                        key={`attr-category2-${c}-${i}`}
                      >
                        <div className="name">{c != '' ? i18n.get_custom_category(attribute.values.filter(a => a.custom_category_text == c)[0]) : i18n.t("other_filter")}</div>
                        <div className="choices">
                          {
                            
                            attribute.values.filter(a => a.custom_category_text == c).map((value, i) => {
                              return (
                                <div
                                key={`attr-child2-${i}`}
                                  className={`size-choice choice ${this.state.filterAttributesValues.indexOf(value.id) > -1 ? 'selected' : ''}`}
                                  onClick={() => this.chooseFilterAttributesValue(value.id)}
                                >
                                  {i18n.get_name(value)}
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    );
                  })
                })
                  
              }
            </div>
          </div>
        </div>
        <div className='products-wrapper'>
          {
            Array.isArray(this.state.subcategories) && this.state.subcategories.length ? 
            (
              <div className="categories-page">
                <h3 className="title">{i18n.t('subcategories')}</h3>
                <CategoriesItem categories={this.state.subcategories} />
                
              </div>
            ) : ( '' )
          }

          <div className={`product-items-wrapper d-flex ${this.state.viewType === 'grid' ? 'flex-direction-column' : 'flex-direction-row'}`}>
            <h3 className="title title-padding">{i18n.t('products_navigation')}</h3>
            <div className='d-flex options'>
              <div className='have-found'>
                <span>
                  {i18n.t('have_found')} { this.state.total } {i18n.t('have_found2')}
                </span>
              </div>
              <div className={`btn-sort d-none ${this.state.sortMenuOpen ? 'open' : ''}`}>
                <button
                  type="button"
                  className="btn btn-outline mr-1"
                  onClick={() => this.setState({ ...this.state, sortMenuOpen: !this.state.sortMenuOpen })}
                >
                  <FontAwesomeIcon icon={faSort} />
                  &nbsp;
                  {i18n.t('sort_by')}
                </button>
                <div className="menu">
                  <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'date-desc' ? 'selected' : ''}`} onClick={() => this.sort('date-desc')}>{i18n.t('date_high_to_low')}</span>
                  <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-asc' ? 'selected' : ''}`} onClick={() => this.sort('price-asc')}>{i18n.t('price_low_to_high')}</span>
                  <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-desc' ? 'selected' : ''}`} onClick={() => this.sort('price-desc')}>{i18n.t('price_high_to_low')}</span>
                </div>
              </div>  
            </div>
            {/* <div className={`btn-change-view-type ml-1 ${this.state.viewTypeMenuOpen ? 'open' : ''}`}>
              <button
                type="button"
                className="btn btn-outline mr-1"
                onClick={() => this.setState({ ...this.state, viewTypeMenuOpen: !this.state.viewTypeMenuOpen })}
              >
                {i18n.t('products_view_type')}
              </button>
              <div className="menu">
                <span className={`${this.state.viewType === 'grid' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'grid', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('grid')}</span>
                <span className={`${this.state.viewType === 'list' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'list', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('list')}</span>
              </div>
            </div> */}
            <div className={`list mt-5`}>
              {
                this.state.fetchingProducts === false && this.state.products.map((product, i) => {
                  let ProductComponent;
                  if (this.state.viewType === 'grid') {
                    ProductComponent = <ProductItem
                      key={i}
                      data={product}
                    />;
                  
                  } else if (this.state.viewType === 'list') {
                    ProductComponent = <ProductListItem
                      key={i}
                      data={product}
                    />
                  }

                  return ProductComponent;
                })
              }
              {
                (this.state.fetchingProducts) && Array.from(Array(12)).map((n, i) => {
                  return (
                    <SkeletonProductItem key={i} type={this.state.viewType}></SkeletonProductItem>
                  );
                })
              }
              {
                this.state.fetchingProducts === false && this.state.products.length === 0 && this.state.fetchIsDirty && <div
                  className="no-results-message"
                > 
                  <FontAwesomeIcon icon={faBoxOpen} />
                  <span>{i18n.t('no_products_found')}</span>
                </div>
              }
            </div>

          </div>
          {
            !this.state.page
              ? <div className="skeleton-pagination"><div></div><div></div><div></div></div>
              : ''
          }
          <div className={`pagination ${!this.state.page ? 'hidden' : ''}`}>
            <div onClick={() => this.changePage(Math.max(1, this.state.page - 1))}><FontAwesomeIcon icon={faAngleLeft} /></div>
            {
              this.state.page > 3
                ? <div onClick={() => this.changePage(1)}>1</div>
                : ''
            }
            {
              this.state.page > 2
                ? <div onClick={() => this.changePage(this.state.page - 2)}>{this.state.page - 2}</div>
                : ''
            }
            {
              this.state.page > 1
                ? <div onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</div>
                : ''
            }
            {
              this.state.pagesCount > 0
                ? <div className="active">{this.state.page}</div>
                : ''
            }
            {
              parseInt(this.state.page) + 1 <= this.state.pagesCount
                ? <div onClick={() => this.changePage(parseInt(this.state.page) + 1)}>{parseInt(this.state.page) + 1}</div>
                : ''
            }
            {
              parseInt(this.state.page) + 2 <= this.state.pagesCount
                ? <div onClick={() => this.changePage(parseInt(this.state.page) + 2)}>{parseInt(this.state.page) + 2}</div>
                : ''
            }
            {
              parseInt(this.state.page) + 3 <= this.state.pagesCount
                ? <div onClick={() => this.changePage(parseInt(this.state.page) + 3)}>{parseInt(this.state.page) + 3}</div>
                : ''
            }
            
            <div onClick={() => this.changePage(Math.min(this.state.pagesCount, parseInt(this.state.page) + 1))}><FontAwesomeIcon icon={faAngleRight} /></div>
          </div>
        </div>
        
      </div>
    </div>
    );
  }
}

export default ProductsPage;